import React from 'react';
// import { useMediaQuery } from 'react-responsive';
// import Menu from './menu';
// import MobileMenu from './mobilemenu';
// import InteriorHomeImage from '../components/visuals/interior';
// import MoodHomeImage from '../components/visuals/mood';
// import BusinessHomeImage from '../components/visuals/business';
import './square.scss';

export default function Square() {
    // const isDesktopScreen = useMediaQuery({ query: '(min-width: 768px)' })
    return (
        <div className='square'>

        </div>
    )
}
